import React, { useEffect, useState } from 'react';
import { ref, onValue } from 'firebase/database';
import { db } from '../firebase';

interface PointsOverviewProps {
  customerId: string;
  availablePoints?: number;
}

const PointsOverview: React.FC<PointsOverviewProps> = ({ customerId, availablePoints }) => {
  const [totalPoints, setTotalPoints] = useState(0);
  const [usedPoints, setUsedPoints] = useState(0);

  useEffect(() => {
    const tasksRef = ref(db, `tasks/${customerId}`);
    onValue(tasksRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const tasks = Object.values(data) as any[];
        const total = tasks.reduce((sum, task) => sum + task.points, 0);
        const used = tasks.filter(task => task.status === 'completed').reduce((sum, task) => sum + task.points, 0);
        setTotalPoints(total);
        setUsedPoints(used);
      }
    });
  }, [customerId]);

  const remainingPoints = availablePoints ? availablePoints - usedPoints : 0;
  const progressPercentage = availablePoints ? (usedPoints / availablePoints) * 100 : 0;

  return (
    <div className="mb-8 bg-white p-6 rounded-lg shadow">
      <h2 className="text-xl font-bold mb-4">Points Overview</h2>
      <div className="mb-4">
        <div className="flex justify-between mb-1">
          <span>Progress</span>
          <span>{progressPercentage.toFixed(0)}%</span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2.5">
          <div
            className="bg-blue-600 h-2.5 rounded-full"
            style={{ width: `${progressPercentage}%` }}
          ></div>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4 text-center">
        <div>
          <p className="text-sm text-gray-600">Available Points</p>
          <p className="text-lg font-semibold">{availablePoints || 0}</p>
        </div>
        <div>
          <p className="text-sm text-gray-600">Used Points</p>
          <p className="text-lg font-semibold">{usedPoints}</p>
        </div>
        <div>
          <p className="text-sm text-gray-600">Remaining Points</p>
          <p className="text-lg font-semibold">{remainingPoints}</p>
        </div>
      </div>
    </div>
  );
};

export default PointsOverview;