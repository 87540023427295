import React, { useState, useEffect } from 'react';
import { IoClose } from 'react-icons/io5';

interface EditCustomerModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (newName: string) => void;
  currentName: string;
}

const EditCustomerModal: React.FC<EditCustomerModalProps> = ({ isOpen, onClose, onSave, currentName }) => {
  const [newName, setNewName] = useState(currentName);

  // Reset newName when the modal opens with a different customer
  useEffect(() => {
    setNewName(currentName);
  }, [currentName]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Edit Customer Name</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <IoClose className="w-6 h-6" />
          </button>
        </div>
        <input
          type="text"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded mb-4"
          placeholder="Enter new name"
        />
        <div className="flex justify-end">
          <button
            onClick={() => {
              onSave(newName);
              onClose();
            }}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditCustomerModal;