import React, { useState } from 'react';
import { ref, push } from 'firebase/database';
import { db } from '../firebase';
import { IoAdd, IoClose } from 'react-icons/io5';

interface TaskFormProps {
  customerId: string;
}

const TaskForm: React.FC<TaskFormProps> = ({ customerId }) => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [points, setPoints] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (title.trim() && points) {
      const tasksRef = ref(db, `tasks/${customerId}`);
      await push(tasksRef, {
        title: title.trim(),
        points: parseInt(points),
        status: 'idea'
      });
      setTitle('');
      setPoints('');
      setIsFormOpen(false);
    }
  };

  return (
    <div className="mb-8">
      {!isFormOpen ? (
        <button
          onClick={() => setIsFormOpen(true)}
          className="flex items-center justify-center w-full p-2 bg-gray-100 text-gray-600 rounded-lg hover:bg-gray-200 transition-colors duration-300"
        >
          <IoAdd className="mr-2" />
          Add New Task
        </button>
      ) : (
        <div className="bg-white p-4 rounded-lg shadow-md">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold text-gray-700">Add New Task</h2>
            <button
              onClick={() => setIsFormOpen(false)}
              className="text-gray-500 hover:text-gray-700 transition-colors duration-300"
            >
              <IoClose className="w-5 h-5" />
            </button>
          </div>
          <form onSubmit={handleSubmit} className="space-y-4">
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Task Title"
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-200"
            />
            <input
              type="number"
              value={points}
              onChange={(e) => setPoints(e.target.value)}
              placeholder="Points"
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-200"
            />
            <div className="flex justify-end">
              <button
                type="submit"
                className="px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 transition-colors duration-300"
              >
                Add Task
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default TaskForm;