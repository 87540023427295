import React, { useEffect, useState } from 'react';
import { ref, onValue, remove } from 'firebase/database';
import { db } from '../firebase';
import { IoTrash } from 'react-icons/io5';

interface ArchivedTask {
  id: string;
  title: string;
  points: number;
  status: string;
  archivedAt: string;
}

interface ArchiveProps {
  customerId: string;
}

const Archive: React.FC<ArchiveProps> = ({ customerId }) => {
  const [archivedTasks, setArchivedTasks] = useState<ArchivedTask[]>([]);

  useEffect(() => {
    const tasksRef = ref(db, `tasks/${customerId}`);
    onValue(tasksRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const taskList = Object.entries(data)
          .map(([id, task]: [string, any]) => ({
            id,
            ...task,
          }))
          .filter(task => task.archivedAt)
          .sort((a, b) => new Date(b.archivedAt).getTime() - new Date(a.archivedAt).getTime());
        setArchivedTasks(taskList);
      }
    });
  }, [customerId]);

  const handleDeleteTask = async (taskId: string) => {
    const taskRef = ref(db, `tasks/${customerId}/${taskId}`);
    await remove(taskRef);
  };

  return (
    <div className="mb-8">
      <h2 className="text-xl font-bold mb-4">Archive</h2>
      <ul className="bg-white p-4 rounded shadow">
        {archivedTasks.map((task) => (
          <li key={task.id} className="mb-2 flex justify-between items-center">
            <span>{task.title} - {task.points} points (Archived: {new Date(task.archivedAt).toLocaleDateString()})</span>
            <button
              onClick={() => handleDeleteTask(task.id)}
              className="p-1 text-gray-600 hover:text-red-600 transition-colors duration-300"
              aria-label="Delete task"
            >
              <IoTrash className="w-5 h-5" />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Archive;