import React, { useEffect, useState } from 'react';
import { ref, onValue } from 'firebase/database';
import { db } from '../firebase';
import { IoPersonOutline, IoStarOutline, IoStarSharp } from 'react-icons/io5';

interface Customer {
  id: string;
  name: string;
  availablePoints: number;
  usedPoints: number;
  isFavorite: boolean;
}

interface DashboardProps {
  onSelectCustomer: (customerId: string) => void;
}

const Dashboard: React.FC<DashboardProps> = ({ onSelectCustomer }) => {
  const [customers, setCustomers] = useState<Customer[]>([]);

  useEffect(() => {
    const customersRef = ref(db, 'customers');
    onValue(customersRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const customerList = Object.entries(data).map(([id, customer]: [string, any]) => ({
          id,
          name: customer.name,
          availablePoints: customer.availablePoints || 0,
          usedPoints: 0,
          isFavorite: customer.isFavorite || false,
        }));

        // Fetch used points for each customer
        customerList.forEach((customer) => {
          const tasksRef = ref(db, `tasks/${customer.id}`);
          onValue(tasksRef, (taskSnapshot) => {
            const taskData = taskSnapshot.val();
            if (taskData) {
              const usedPoints = Object.values(taskData).reduce((sum: number, task: any) => {
                return sum + (task.status === 'completed' ? task.points : 0);
              }, 0);
              customer.usedPoints = usedPoints;
              setCustomers([...customerList]);
            }
          });
        });

        // Sort customers by remaining points (available - used)
        const sortedCustomers = customerList.sort((a, b) => 
          (b.availablePoints - b.usedPoints) - (a.availablePoints - a.usedPoints)
        );
        setCustomers(sortedCustomers);
      }
    });
  }, []);

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Customer Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {customers.map((customer) => (
          <div 
            key={customer.id} 
            className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 cursor-pointer"
            onClick={() => onSelectCustomer(customer.id)}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold flex items-center">
                <IoPersonOutline className="mr-2" />
                {customer.name}
              </h2>
              {customer.isFavorite ? (
                <IoStarSharp className="text-yellow-400 w-6 h-6" />
              ) : (
                <IoStarOutline className="text-gray-400 w-6 h-6" />
              )}
            </div>
            <div className="space-y-2">
              <p>Available Points: {customer.availablePoints}</p>
              <p>Used Points: {customer.usedPoints}</p>
              <p className="font-semibold">
                Remaining Points: {customer.availablePoints - customer.usedPoints}
              </p>
            </div>
            <div className="mt-4 bg-gray-200 h-2 rounded-full">
              <div 
                className="bg-blue-500 h-2 rounded-full"
                style={{ width: `${(customer.usedPoints / customer.availablePoints) * 100}%` }}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;