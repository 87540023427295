import React, { useEffect, useState } from 'react';
import { ref, onValue, update, push, remove } from 'firebase/database';
import { db } from '../firebase';
import { IoArrowForward, IoTrash, IoPencil, IoPlay, IoPause, IoTime } from 'react-icons/io5';

interface SupportTicket {
  id: string;
  title: string;
  status: 'idea' | 'in_progress' | 'completed';
  timeSpent: number; // in seconds
  isTimerRunning: boolean;
  lastStartTime?: number;
  description?: string;
}

const columns = [
  { id: 'idea', title: 'New' },
  { id: 'in_progress', title: 'In Progress' },
  { id: 'completed', title: 'Completed' },
];

const SupportTicketList: React.FC<{ customerId: string }> = ({ customerId }) => {
  const [tickets, setTickets] = useState<SupportTicket[]>([]);
  const [editingTicket, setEditingTicket] = useState<SupportTicket | null>(null);
  const [description, setDescription] = useState('');
  const [editingTime, setEditingTime] = useState('');

  useEffect(() => {
    const ticketsRef = ref(db, `supportTickets/${customerId}`);
    const unsubscribe = onValue(ticketsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const now = Date.now();
        const ticketList = Object.entries(data).map(([id, ticket]: [string, any]) => {
          const timeSpent = ticket.isTimerRunning && ticket.lastStartTime
            ? ticket.timeSpent + Math.floor((now - ticket.lastStartTime) / 1000)
            : ticket.timeSpent || 0;
          return {
            id,
            ...ticket,
            timeSpent,
          };
        });
        setTickets(ticketList);
      } else {
        setTickets([]);
      }
    });

    return () => unsubscribe();
  }, [customerId]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTickets(prevTickets =>
        prevTickets.map(ticket => {
          if (ticket.isTimerRunning) {
            return { ...ticket, timeSpent: ticket.timeSpent + 1 };
          }
          return ticket;
        })
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const moveTicket = async (ticketId: string, currentStatus: string) => {
    const currentIndex = columns.findIndex(col => col.id === currentStatus);
    if (currentIndex < columns.length - 1) {
      const newStatus = columns[currentIndex + 1].id;
      const ticketRef = ref(db, `supportTickets/${customerId}/${ticketId}`);
      await update(ticketRef, { status: newStatus });
    }
  };

  const toggleTimer = async (ticket: SupportTicket) => {
    const ticketRef = ref(db, `supportTickets/${customerId}/${ticket.id}`);
    const now = Date.now();
    if (ticket.isTimerRunning) {
      await update(ticketRef, { 
        isTimerRunning: false, 
        timeSpent: ticket.timeSpent,
        lastStartTime: null
      });
    } else {
      await update(ticketRef, { 
        isTimerRunning: true, 
        lastStartTime: now 
      });
    }
  };

  const deleteTicket = async (ticketId: string) => {
    if (window.confirm('Are you sure you want to delete this ticket?')) {
      const ticketRef = ref(db, `supportTickets/${customerId}/${ticketId}`);
      await remove(ticketRef);
    }
  };

  const openEditModal = (ticket: SupportTicket) => {
    setEditingTicket(ticket);
    setDescription(ticket.description || '');
    setEditingTime(formatTime(ticket.timeSpent));
  };

  const closeEditModal = () => {
    setEditingTicket(null);
    setDescription('');
    setEditingTime('');
  };

  const saveDescription = async () => {
    if (editingTicket) {
      const ticketRef = ref(db, `supportTickets/${customerId}/${editingTicket.id}`);
      const [hours, minutes, seconds] = editingTime.split(':').map(Number);
      const newTimeSpent = hours * 3600 + minutes * 60 + seconds;
      await update(ticketRef, { 
        description,
        timeSpent: newTimeSpent
      });
      closeEditModal();
    }
  };

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="mb-8">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Support Tickets</h2>
      <div className="md:hidden overflow-x-auto">
        <div className="flex space-x-4 min-w-max">
          {columns.map((column) => (
            <div key={column.id} className="w-72 bg-white p-4 rounded-xl shadow-md">
              <h3 className="text-base font-semibold mb-4 text-gray-700 capitalize">{column.title}</h3>
              <div className="space-y-3">
                {tickets
                  .filter((ticket) => ticket.status === column.id)
                  .map((ticket) => (
                    <div 
                      key={ticket.id} 
                      className="bg-gray-50 p-3 rounded-lg shadow-sm transition-shadow duration-300 hover:shadow-md cursor-pointer"
                      onDoubleClick={() => openEditModal(ticket)}
                    >
                      <div className="flex justify-between items-start mb-2">
                        <h4 className="text-sm font-medium text-gray-800">{ticket.title}</h4>
                        <span className="bg-gray-200 text-gray-700 px-2 py-1 rounded-full text-xs font-semibold">
                          {formatTime(ticket.timeSpent)}
                        </span>
                      </div>
                      {renderTicketActions(ticket, column.id)}
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="hidden md:block">
        <div className="grid grid-cols-3 gap-6">
          {columns.map((column) => (
            <div key={column.id} className="bg-white p-4 rounded-xl shadow-md">
              <h3 className="text-lg font-semibold mb-4 text-gray-700 capitalize">{column.title}</h3>
              <div className="space-y-3">
                {tickets
                  .filter((ticket) => ticket.status === column.id)
                  .map((ticket) => (
                    <div 
                      key={ticket.id} 
                      className="bg-gray-50 p-3 rounded-lg shadow-sm transition-shadow duration-300 hover:shadow-md cursor-pointer"
                      onDoubleClick={() => openEditModal(ticket)}
                    >
                      <div className="flex justify-between items-start mb-2">
                        <h4 className="text-base font-medium text-gray-800">{ticket.title}</h4>
                        <span className="bg-gray-200 text-gray-700 px-2 py-1 rounded-full text-xs font-semibold">
                          {formatTime(ticket.timeSpent)}
                        </span>
                      </div>
                      {renderTicketActions(ticket, column.id)}
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      {renderEditModal()}
    </div>
  );

  function renderTicketActions(ticket: SupportTicket, columnId: string) {
    return (
      <div className="flex justify-end items-center space-x-1">
        <button
          onClick={() => toggleTimer(ticket)}
          className="p-1 text-gray-600 hover:text-gray-800 transition-colors duration-300"
          aria-label={ticket.isTimerRunning ? "Pause timer" : "Start timer"}
        >
          {ticket.isTimerRunning ? <IoPause className="w-4 h-4" /> : <IoPlay className="w-4 h-4" />}
        </button>
        <button
          onClick={() => openEditModal(ticket)}
          className="p-1 text-gray-600 hover:text-gray-800 transition-colors duration-300"
          aria-label="Edit ticket"
        >
          <IoPencil className="w-4 h-4" />
        </button>
        {columnId !== 'completed' && (
          <button
            onClick={() => moveTicket(ticket.id, ticket.status)}
            className="p-1 text-gray-600 hover:text-gray-800 transition-colors duration-300"
            aria-label="Move ticket"
          >
            <IoArrowForward className="w-4 h-4" />
          </button>
        )}
        <button
          onClick={() => deleteTicket(ticket.id)}
          className="p-1 text-gray-600 hover:text-red-600 transition-colors duration-300"
          aria-label="Delete ticket"
        >
          <IoTrash className="w-4 h-4" />
        </button>
      </div>
    );
  }

  function renderEditModal() {
    if (!editingTicket) return null;
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg p-6 w-full max-w-2xl">
          <h3 className="text-xl font-bold mb-4 text-gray-800">{editingTicket.title}</h3>
          <div className="mb-4 flex items-center">
            <IoTime className="mr-2 text-gray-600" />
            <input
              type="text"
              value={editingTime}
              onChange={(e) => setEditingTime(e.target.value)}
              placeholder="HH:MM:SS"
              className="p-2 border border-gray-300 rounded text-gray-700"
            />
          </div>
          <textarea
            className="w-full h-64 p-2 border border-gray-300 rounded mb-4 text-gray-700"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter ticket description..."
          />
          <div className="flex justify-end space-x-2">
            <button
              onClick={closeEditModal}
              className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors duration-300"
            >
              Cancel
            </button>
            <button
              onClick={saveDescription}
              className="px-4 py-2 bg-gray-800 text-white rounded hover:bg-gray-700 transition-colors duration-300"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
};

export default SupportTicketList;