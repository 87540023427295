import React, { useState, useEffect } from 'react';
import { ref, onValue, push, update, remove } from 'firebase/database';
import { db } from '../firebase';
import { IoAdd, IoTrash, IoPencil, IoClose } from 'react-icons/io5';

interface Doc {
  id: string;
  title: string;
  content: string;
}

interface DocsProps {
  customerId: string;
}

const Docs: React.FC<DocsProps> = ({ customerId }) => {
  const [docs, setDocs] = useState<Doc[]>([]);
  const [editingDoc, setEditingDoc] = useState<Doc | null>(null);
  const [isCreating, setIsCreating] = useState(false);
  const [newDocTitle, setNewDocTitle] = useState('');
  const [newDocContent, setNewDocContent] = useState('');

  useEffect(() => {
    const docsRef = ref(db, `docs/${customerId}`);
    onValue(docsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const docList = Object.entries(data).map(([id, doc]: [string, any]) => ({
          id,
          ...doc,
        }));
        setDocs(docList);
      } else {
        setDocs([]);
      }
    });
  }, [customerId]);

  const handleCreateDoc = async () => {
    if (newDocTitle.trim()) {
      const docsRef = ref(db, `docs/${customerId}`);
      await push(docsRef, { title: newDocTitle.trim(), content: '' });
      setNewDocTitle('');
      setIsCreating(false);
    }
  };

  const handleEditDoc = (doc: Doc) => {
    setEditingDoc(doc);
    setNewDocTitle(doc.title);
    setNewDocContent(doc.content);
  };

  const handleSaveDoc = async () => {
    if (editingDoc) {
      const docRef = ref(db, `docs/${customerId}/${editingDoc.id}`);
      await update(docRef, { title: newDocTitle, content: newDocContent });
      setEditingDoc(null);
      setNewDocTitle('');
      setNewDocContent('');
    }
  };

  const handleDeleteDoc = async (docId: string) => {
    if (window.confirm('Are you sure you want to delete this document?')) {
      const docRef = ref(db, `docs/${customerId}/${docId}`);
      await remove(docRef);
    }
  };

  return (
    <div className="mb-8">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Documents</h2>
        <button
          onClick={() => setIsCreating(true)}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-300 flex items-center"
        >
          <IoAdd className="mr-2" />
          New Document
        </button>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {docs.map((doc) => (
          <div
            key={doc.id}
            className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
          >
            <h3 className="text-lg font-semibold mb-2 truncate">{doc.title}</h3>
            <p className="text-gray-600 h-20 overflow-hidden">{doc.content}</p>
            <div className="mt-4 flex justify-end space-x-2">
              <button
                onClick={() => handleEditDoc(doc)}
                className="p-2 text-blue-600 hover:bg-blue-100 rounded-full transition-colors duration-300"
                title="Edit"
              >
                <IoPencil className="w-5 h-5" />
              </button>
              <button
                onClick={() => handleDeleteDoc(doc.id)}
                className="p-2 text-red-600 hover:bg-red-100 rounded-full transition-colors duration-300"
                title="Delete"
              >
                <IoTrash className="w-5 h-5" />
              </button>
            </div>
          </div>
        ))}
      </div>
      {(isCreating || editingDoc) && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 w-full max-w-2xl">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold">
                {isCreating ? 'Create New Document' : 'Edit Document'}
              </h3>
              <button
                onClick={() => {
                  setIsCreating(false);
                  setEditingDoc(null);
                  setNewDocTitle('');
                  setNewDocContent('');
                }}
                className="text-gray-500 hover:text-gray-700"
              >
                <IoClose className="w-6 h-6" />
              </button>
            </div>
            <input
              type="text"
              value={newDocTitle}
              onChange={(e) => setNewDocTitle(e.target.value)}
              placeholder="Document Title"
              className="w-full p-2 border border-gray-300 rounded mb-4"
            />
            <textarea
              className="w-full h-64 p-2 border border-gray-300 rounded mb-4"
              value={newDocContent}
              onChange={(e) => setNewDocContent(e.target.value)}
              placeholder="Document Content"
            />
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => {
                  setIsCreating(false);
                  setEditingDoc(null);
                  setNewDocTitle('');
                  setNewDocContent('');
                }}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 transition-colors duration-300"
              >
                Cancel
              </button>
              <button
                onClick={isCreating ? handleCreateDoc : handleSaveDoc}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-300"
              >
                {isCreating ? 'Create' : 'Save'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Docs;