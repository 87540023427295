import React, { useEffect, useState } from 'react';
import { ref, onValue } from 'firebase/database';
import { db } from '../firebase';

interface MonthlyPoints {
  [key: string]: number;
}

interface MonthlyPointsOverviewProps {
  customerId: string;
}

const MonthlyPointsOverview: React.FC<MonthlyPointsOverviewProps> = ({ customerId }) => {
  const [monthlyPoints, setMonthlyPoints] = useState<MonthlyPoints>({});

  useEffect(() => {
    const tasksRef = ref(db, `tasks/${customerId}`);
    onValue(tasksRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const points: MonthlyPoints = {};
        Object.values(data).forEach((task: any) => {
          if (task.archivedAt) {
            const date = new Date(task.archivedAt);
            const monthYear = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
            points[monthYear] = (points[monthYear] || 0) + task.points;
          }
        });
        setMonthlyPoints(points);
      }
    });
  }, [customerId]);

  return (
    <div className="mb-8">
      <h2 className="text-xl font-bold mb-4">Monthly Points Overview</h2>
      <ul className="bg-white p-4 rounded shadow">
        {Object.entries(monthlyPoints)
          .sort((a, b) => b[0].localeCompare(a[0]))
          .map(([month, points]) => (
            <li key={month} className="mb-2">
              {month}: {points} points
            </li>
          ))}
      </ul>
    </div>
  );
};

export default MonthlyPointsOverview;